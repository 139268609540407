<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <!-- Card -->
        <div class="column cardSize is-two-thirds box p-5">
          <!-- Main Content -->

          <!-- SmartTag -->
          <h1 class="pb-2 heading has-text-centered">Account</h1>
          <div class="tag_box">
            <SmartTag class="py-3 is-centered" :tags="mainTagInput" />
          </div>
          <hr />

          <form>
            <h1 class="heading has-text-centered">Profile</h1>
            <b-field label="Username">
              <b-taginput
                placeholder="JohnSmith"
                attached
                maxlength="20"
                maxtags="1"
                type="is-name"
                v-model="user.username"
              >
              </b-taginput>
            </b-field>
            <!-- email -->
            <b-field label="Email">
              <b-input
                v-model="user.email"
                type="email"
                placeholder="john.smith@email.com"
                maxlength="30"
              >
              </b-input>
            </b-field>
            <hr />
            <h1 class="heading has-text-centered">Personal Details</h1>
            <!-- name -->
            <b-field label="First name">
              <b-input
                placeholder="John"
                maxlength="20"
                v-model="user.first_name"
              ></b-input>
            </b-field>

            <!-- surname -->
            <b-field label="Surname">
              <b-input
                v-model="user.surname"
                placeholder="Smith"
                maxlength="20"
              ></b-input>
            </b-field>

            <!-- dob -->
            <b-field label="Date of birth">
              <b-datepicker
                v-model="date"
                placeholder="Type or select a date..."
                icon="calendar-today"
                :locale="locale"
                editable
              >
              </b-datepicker>
            </b-field>

            <!-- phone -->
            <b-field label="Phone">
              <b-input
                v-model="user.phone"
                type="phone"
                placeholder="+33 7 498 899 948"
                maxlength="30"
              >
              </b-input>
            </b-field>

            <hr />
            <h1 class="heading has-text-centered">Abilities</h1>

            <!-- skills -->
            <b-field label="Skills">
              <b-taginput
                attached
                maxlength="25"
                maxtags="10"
                type="is-skill"
                v-model="user.skills"
              >
              </b-taginput>
            </b-field>

            <!-- interests -->
            <b-field label="Interests">
              <b-taginput
                attached
                maxlength="25"
                maxtags="5"
                type="is-skill"
                v-model="user.interests"
              >
              </b-taginput>
            </b-field>
            <hr />
            <div class="tag_box">
              <!-- Save button -->
              <button
                type="submit"
                class="mr-3 is-primary-outlined mt-3 button"
              >
                Save
              </button>
              <!-- Cancel button -->
              <button type="button" class="is-primary-outlined mt-3 button">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SmartTag from "@/components/SmartTag.vue";
export default {
  name: "account-view",
  components: {
    SmartTag,
  },

  data() {
    return {
      date: new Date(),
      user: {
        id: "0",
        first_name: "John",
        surname: "Smith",
        username: ["JohnSmith"],
        dob: "",
        email: "j.smith92@email.com",
        phone: "+447492808558",
        previous_roles: ["UX Lead", "PM", "Flask Dev"],
        skills: ["CSS", "Figma", "Python", "Powerpoint"],
        interests: ["Robotics", "UX Design", "Presenting"],
        objectives_completed: 483,
        tasks_completed: 78,
        projects_completed: 24,
        earnings: "$9230",
        current_projects: [
          { project: "EV Hoverbike", role: "Software Architect" },
          { project: "e-store", role: "CSS Styles Dev" },
        ],
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolo im diam quis enim lobortis scelerisque fermentum dui. Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus nullam. Molestie at elementum eu facilisis sed odio morbi. Leo vel orci porta non pulvinar neque. In ornare quam viverra orci sagittis eu. Est velit egestas dui id ornare arcu. Libero nunc consequat interdum varius sit.",
        subtasks: [
          { task: "Preliminary sketches", completed_status: true },
          { task: "I/O Locked down", completed_status: true },
          { task: "Full Simulink model packaged", completed_status: false },
          { task: "Fully Debug program", completed_status: false },
        ],
        skill: "Simulink",
        date: "20-Dec",
        setter: "DevPatel",
        setter_role: "PM",
        team: [
          { role: "Fluid Software Lead", name: "ShivM" },
          { role: "Heat Pump Engineer", name: "RichardE" },
          { role: "Brake System Lead", name: "HuChanW" },
        ],
      },
    };
  },
  computed: {
    mainTagInput() {
      return [
        { type: "is-name", value: "JohnSmith" },
        {
          type: "is-skill",
          value: "Python",
        },
      ];
    },
  },
};
</script>

<style scoped>
.cardSize {
  max-width: 750px;
}
</style>
